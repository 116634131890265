.zubuz-thumb{
  position: relative;
  @include max(991px) {
    margin-bottom: 35px;
  }
  &.thumb-pr{
    padding-right: 100px;
    @include max(991px) {
      padding-right: 0;
    }
  }
  &.thumb-pl{
    padding-left: 100px;
    @include max(1300px) {
      padding-left: 50px;
    }
    @include max(991px) {
      padding-left: 0;
    }
  }
}

.zubuz-thumb-card {
  position: absolute;
  bottom: 60px;
  right: 15px;
  animation: float-top-bottom  6s ease-in-out infinite;
  @include max(1199px) {
    width: 230px;
  }
}
.zubuz-thumb-card2 {
  position: absolute;
  bottom: 60px;
  right: -18%;
  animation: float-top-bottom  6s ease-in-out infinite;
  @include max(1300px) {
    width: 230px;
    right: 0;
  }
}

@keyframes float-top-bottom {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-40px);
	}
	100% {
		transform: translatey(0px);
	}
}

.zubuz-v2-thumb{
  position: relative;
  z-index: 0;
  &.thumb-mr{
    margin-right: 65px;
    @include max(1199px) {
      margin-right: 15px;
    }
    @include max(991px) {
      margin-right: 0;
    }
  }
  &.thumb-ml{
    margin-left: 65px;
    @include max(1199px) {
      margin-left: 15px;
    }
    @include max(991px) {
      margin-left: 0;
    }
  }
  
  @include max(991px) {
    margin-bottom: 40px;
  }
  img{
    border-radius: 10px;
  }
  &-shape{
    position: absolute;
    left: -30%;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    @include max(991px) {
      display: none;
    }
  }
}

.zubuz-v3-thumb {
  margin-right: 27px;
  position: relative;
  img{
    border-radius: 20px;
  }
  @include max(991px) {
    margin-right: 0;
    margin-bottom: 40px;
  }
}

.zubuz-v3-card {
  bottom: 40px;
  left: -100px;
  width: 65%;
  overflow: hidden;
  position: absolute;
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.12);
  animation: float-top-bottom  6s ease-in-out infinite;
  img{
    border-radius: 10px;
  }
  @include max(1299px) {
    left: -20px;
  }
  @include max(767px) {
    display: none;
  }
}

.zubuz-v3-thumb2 {
  margin-left: 30px;
  position: relative;
  img{
    border-radius: 20px;
  }
  @include max(991px) {
    margin-left: 0;
    margin-bottom: 40px;
  }
}

.zubuz-v3-card2{
  bottom: 55px;
  left: -20px;
  width: 65%;
  overflow: hidden;
  
  position: absolute;
  box-shadow: 0px 2.534px 38.004px 0px rgba(0, 0, 0, 0.12);
  animation: float-top-bottom  6s ease-in-out infinite;
  img{
    border-radius: 10px;
  }
  @include max(1299px) {
    left: -20px;
  }
  @include max(767px) {
    display: none;
  }
}

.zubuz-career-thumb {
  border-radius: 20px;
  overflow: hidden;
  margin-right: 20px;
  @include max(991px) {
    margin-right: 0;
    margin-bottom: 40px;
  }
}