
.zubuz-extra-section{
  padding: 200px 0 130px;
  @include max(991px) {
    padding: 160px 0 100px;
  }
  @include max(767px) {
    padding: 160px  0 100px;
  }

  @include max(479px) {
    padding: 130px 0 100px;
  }
}
.zubuz-account-checkbox-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
a.forgot-password{
  font-size: 16px;
  color: var(--heading-color);
}
.zubuz-account{
  &-wrap{
    padding: 40px;
    max-width: 856px;
    border-radius: 10px;
    margin: 0 auto;
    border: 1px solid var(--heading-color);
    @include max(991px) {
      padding: 30px;
    }
  }
  &-field{
    margin-bottom: 24px;
    label{
      font-weight: 700;
      display: block;
      font-size: 18px;
      margin-bottom: 10px;
    }
    input{
      font-weight: 400;
      border: 1px solid var(--heading-color)!important;
      &:focus, 
      &:focus{
         border: 1px solid var(--primary-color)!important;
      }
    }
  }
  &-checkbox{
    input{
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }
    label{
      position: relative;
      font-size: 16px;
      display: flex;
      align-items: center;
      a{
        color: var(--body-color);
        font-weight: 600;
        margin: 0 4px;
      }
      &:before{
        content:'';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1.5px solid #7F8995;
        padding: 7px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 10px;
        border-radius: 3px;
      }
    }
  }
}

.zubuz-account-checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 9px;
  left: 6px;
  width: 5px;
  height: 9px;
  border: solid var(--dark-bg);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

button#zubuz-account-btn {
  width: 100%;
  margin: 50px 0;

}

.zubuz-or {
  z-index: 0;
  line-height: 1;
  position: relative;
  text-align: center;
  margin-bottom: 40px;
  p{
    line-height: 1;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
    padding: 0 12px;
    display: inline-block;
    text-align: center;
    background-color: var(--white-bg);
  }
  &::before{
    content: "";
    left: 0;
    top: 9px;
    width: 100%;
    height: 1px;
    z-index: -1;
    position: absolute;
    background-color: #E6E6E6;
  }
}

a.zubuz-connect-login {
  height: 60px;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  border-radius: 3px;
  line-height: 60px;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;
  color: var(--heading-color);
  border: 1px solid var(--dark-bg);
  img{
    margin-right: 15px;
  }
}

.zubuz-account-bottom{
  margin-top: 40px;
  text-align: center;
  p{
    font-size: 16px;
  }
  a{
    font-weight: 600;
    color: var(--body-color);
  }
}

// 404
.zubuz-errors {
  &-wrap{
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
  }
  &-thumb{
    margin-bottom: 80px;
    @include max(991px) {
      margin-bottom: 60px;
    }
    @include max(767px) {
      margin-bottom: 40px;
    }
  }

  
}
