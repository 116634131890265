h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $heading-font;
  color: var(--heading-color);
  font-weight: 700;
  margin: 0;
}
h1,
.h1 {
  z-index: 0;
  font-size: 110px;
  letter-spacing: -2px;
  line-height: 1.04em;
  margin-bottom: 24px;
  position: relative;
  @include max(1600px) { 
    font-size: 98px;
  }
  @include max(1400px) { 
    font-size: 70px;
  }
  @include max(767px) { 
    font-size: 48px;
  }
  @include max(479px) { 
    font-size: 40px;
  }
}
h2,
.h2 {
  font-size: 85px;
  line-height: 1.05em; /* 116.667% */
  letter-spacing: -0.1px;
  @include max(1400px) { 
    font-size: 60px;
  }
  @include max(767px) { 
    font-size: 48px;
  }
  @include max(479px) { 
    font-size: 36px;
  }
}

h3,
.h3 {
  font-size: 30px;
  line-height: 1.4em;
  @include max(767px) {
    line-height: 1.2em;
  }
  @include max(479px) {
    font-size: 24px;
  }

}
h4,
.h4 {
  font-size: 24px;
  line-height: 28px;
}

h5,
.h5 {
  font-size: 20px;
}

h6,
.h6 {
  font-size: 18px;
}

p{
  margin-bottom: 24px;
  @include max(767px) {
    margin-bottom: 20px;
    font-size: 20px;
  }
  &:last-child{
    margin-bottom: 0;
  }
}

// @each $cheadingfont in $headingfontSize {
//   $i: index($headingfontSize, $cheadingfont);
//   h#{$i},
//   .text-h#{$i} {
//     font-size: $cheadingfont;
//     line-height: nth($headingLineheight, $i);
//   }
// }


@for $i from 10 through 40 {
  .f-size-#{$i} {
    font-size: $i * 1px !important;
  }
}

@for $i from 10 through 40 {
  .line-height-#{$i} {
    line-height: $i * 1px !important;
  }
}

.font-bold{
  font-weight: 700;
}
.font-black{
  font-weight: 800;
}
.font-medium{
  font-weight: 500;
}
.font-normal{
  font-weight: 400;
}
.font-semibold{
  font-weight: 600;
}


// @each $currentfont in $bodyfontvariation {
//   $i: index($bodyfontvariation, $currentfont);
//   .body-font-#{$i} {
//     font-size: $currentfont;
//     line-height: nth($lineheightvariation, $i);
//   }
// }

.gray-bg{
  background-color: var(--gray-bg);
}
.yellow-bg{
  background-color: var(--yellow-bg);
}