.zubuz-breadcrumb{
  text-align: center;
  padding: 195px 0 130px;
  background-color: var(--dark-bg);
  @include max(991px) {
    padding: 170px 0 100px;
  }
  @include max(767px) {
    padding: 170px 0 80px;
  }
  @include max(575px) {
    padding: 130px 0 80px;
  }
  &.breadcrumb-light{
    background-color: var(--white-bg);
    border-bottom: 1px solid var(--light-color);
  }
}

h1.post__title{
  margin-bottom: 50px;
  color: var(--white-color);
  @include max(1400px) {
    margin-bottom: 30px;
  }
}
.breadcrumb-light{
  h1.post__title{
    color: var(--heading-color);
   
  }
}

.breadcrumbs{
  ul{
    li{
      font-weight: 700;
      line-height: 1;
      z-index: 0;
      padding: 0 10px;
      position: relative;
      display: inline-block;
      color: var(--primary-color);
      @include max(767px) {
        font-size: 18px;
      }
      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
      }
      &:before {
        content: "";
        right: -10px;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        position: absolute;
        background-size: 9px;
        background-position: center right;
        background-repeat: no-repeat;
        background-image: url(../images/blog/backspece.svg);
      }
      &:last-child:before{
        content: none;
      }
      a{
        font-weight: 400;
        color: var(--white-color);
      }
    }
  }
}
.breadcrumb-light{
  .breadcrumbs{
    ul{
      li{
        &::before{
          background-image: url(../images/blog/backspece2.svg);
        }
        a{
          color: var(--heading-color);
        }
      }
    }
  }
}