.zubuz-team{
  &-wrap{
    margin-bottom: 24px;
  }
  &-thumb{
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }
  &-data{
    left: 20px;
    bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    width: calc(100% - 40px);
    position: absolute;
    background-color: var(--white-bg);
    h4{
      margin-bottom: 10px;
    }
    p{
      margin: 0;
      font-size: 18px;
    }
  }
}

.zubuz-social-icon.social-box.small-box.position {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

// team destails

.zubuz-team-details-thumb{
  border-radius: 10px;
  overflow: hidden;
  @include max(991px) {
    margin-bottom: 40px;
  }
}

.zubuz-team-skill{
  &-wrap{
    display: grid;
    column-gap: 24px;
    row-gap: 60px;
    grid-template-columns: 1fr 1fr 1fr;
    @include max(991px) {
      row-gap: 40px;
      grid-template-columns: 1fr 1fr;
    }
    @include max(767px) {
      row-gap: 24px;
      grid-template-columns: 1fr;
    }
  }
  h3{
    margin-bottom: 15px;
  }
  p{
    font-size: 20px;
  }
  ul{
    li{
      font-size: 20px;
      margin-bottom: 12px;
      position: relative;
      padding-left: 20px;
      &:last-child{
        margin-bottom: 0;
      }
      &::before{
        content: '';
        left: 0;
        top: 11px;
        width: 7px;
        height: 7px;
        border-radius: 10px;
        position: absolute;
        background-color: var(--dark-bg);
      }
    }
  }
}