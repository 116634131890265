.zubuz-hero {
  &-section{
    padding-top: 195px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center bottom;
    @include max(991px) {
      padding-top: 160px;
    }
    @include max(479px) {
        padding-top: 120px;
    }
  }
  &-content{
    &.center{
      text-align: center;
      max-width: 1100px;
      margin: 0 auto;
      padding-bottom: 80px;
      @include max(1400px) {
        max-width: 800px;
      }
      p{
        max-width: 950px;
        margin: 0 auto 50px;
        @include max(991px) {
          margin: 0 auto 30px;
        }
      }
    }
    @include max(991px) {
      text-align: center;
    }
    p{
      margin-bottom: 50px;
      @include max(991px) {
        margin-bottom: 30px;
      }
    }
  }
  &-bottom{
    position: relative;
    text-align: center;
  }
  &-card{
    position: absolute;
    @include max(767px) {
      display: none;
    }
    z-index: 0;
    &.card1{
      top: 5%;
      left: 13%;

      @include max(1299px){
        left: 0;
      }
      @include max(991px) {
        width: 140px;
      }
   }
   &.card2{
    bottom: 16%;
    left: 0;
    @include max(1299px){
      width: 200px;
    }
    @include max(991px) {
      width: 140px;
    }
  }
  &.card3{
    top: -18%;
    right: -5%;
    @include max(1299px){
      width: 230px;
      right: 0;
    }
    @include max(991px) {
      width: 150px;
      top: -5%;
    }
  }
  &.card4{
    bottom: 16%;
    right: 3%;
    @include max(1299px){
      width: 250px;
      right: 0;
    }
    @include max(991px) {
      width: 150px;
    }
  }
}

}

.zubuz-hero-btn-wrap {
    display: flex;
    align-items: center;
    column-gap: 24px;
    &.center{
      justify-content: center;
    }
    @include max(991px) {
      justify-content: center;
    }
    @include max(479px) {
      flex-direction: column;
      row-gap: 20px;
    }
}
.zubuz-hero-video{
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  padding: 13px 22px;
  border-radius: 3px;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  img{
    margin-right: 10px;
  }
}
.zubuz-hero-shape {
  position: absolute;
  bottom: 7%;
  left: -10%;
  animation: float-left-right 6s ease-in-out infinite;
  @include max(1399px) {
    width: 200px;
    left: 2%;
  }
  @include max(991px) {
    display: none;
  }
}

@keyframes float-left-right {
	0% {
		transform: translatex(0px);
	}
	50% {
		transform: translatex(-40px);
	}
	100% {
		transform: translatex(0px);
	}
}
// end v1 hero

// start hero v2
.zubuz_screenfix_right {
  margin-right: calc((-100vw + 100%)/2);
  max-width: 1610px;
  @include max(991px) {
    max-width: 100%;
    margin: 0 auto;
  }
}

.zubuz-hero {
  &-section2{
    position: relative;
    padding: 170px 0 130px;
    @include max(991px) {
      padding: 160px 0 100px;
    }
    @include max(767px) {
      padding: 160px  0 100px;
    }

    @include max(479px) {
      padding: 120px 0 100px;
    }
  }
  &-rating{
    margin-bottom: 20px;
    ul{
      display: flex;
      align-items: center;
      @include max(991px) {
        justify-content: center;
      }
      li{
        font-size: 16px;
        margin-right: 15px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
  &-thumb2{
    left: 15px;
    z-index: 0;
    position: relative;
    margin-left: 65px;
    @include max(991px) {
      left: 0;
      margin-left: 0;
      margin-top: 40px;
    }
  }
  
}

.zubuz-hero-shape2 {
  top: -15%;
  right: 0;
  z-index: -1;
  position: absolute;
  img{
    @include max(1250px) {
      width: 300px;
    }
  }
  @include max(991px) {
    display: none;
  }
}
.zubuz-hero-shape3 {
  position: absolute;
  bottom: -10%;
  left: -15%;
  z-index: -1;
  @include max(767px) {
    display: none;
  }
}

span.zubuz-title-shape {
  top: 0;
  right: -8%;
  z-index: -1;
  position: absolute;
  @include max(1500px) {
    width: 50%;
    right: 0;
  }
  @include max(767px) {
    display: none;
  }
}

// End v3

.zubuz-hero{
  &-thumb3{
    position: relative;
    border-radius: 20px;
    overflow: hidden;
  }
}

.zubuz-hero-thumb {
  &-card1{
    position: absolute;
    bottom: 24px;
    left: 147px;
    animation: float-top-bottom  6s ease-in-out infinite;
    @include max(1300px) {
      left: 50px;
    }
    @include max(991px) {
      display: none;
    }
  }
  &-card2 {
    position: absolute;
    bottom: 64px;
    right: 291px;
    animation: float-left-right 6s ease-in-out infinite;
    @include max(991px) {
      display: none;
    }
  }
  &-card3{
    position: absolute;
    bottom: 164px;
    right: 146px;
    animation: float-top-bottom 6s ease-in-out infinite;
    @include max(991px) {
      display: none;
    }
  }
  
}


.zubuz-border-btoom{
  padding-bottom: 130px;
  border-bottom: 1px solid var(--light-color);
  @include max(991px) {
    padding-bottom: 100px;
  }
  @include max(767px) {
    padding-bottom: 80px;
  }
}