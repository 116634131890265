

.waves {
  position: absolute;
  width: 185px;
  height: 185px;
  opacity: 0;
  border-radius: 100%;
  left: -52px;
  top: -52px;
  z-index: 0;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
  background-color: var(--dark-bg);
  z-index: -1;
  &.waves-2{
    width: 200px;
    height: 200px;
    left: -40px;
    top: -40px;
    background-color: var(--white-bg);
    @include max(991px) {
      left: -62px;
      top: -62px;
    }
  }
  &.wave-1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  &.wave-2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  &.wave-3 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
}


@keyframes waves {
  0% {
      -webkit-transform: scale(0.2, 0.2);
      transform: scale(0.2, 0.2);
      opacity: 0;
  }

  50% {
      opacity: 0.9;
  }

  100% {
      -webkit-transform: scale(0.9, 0.9);
      transform: scale(0.9, 0.9);
      opacity: 0;
  }
}



// img.zubuz-video-icon {
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
// }
// img.zubuz-video-circle{
//   -webkit-animation: rotate-animation 10s infinite linear;
//   -moz-animation: rotate-animation 10s infinite linear;
//   -o-animation: rotate-animation 10s infinite linear;
//   animation: rotate-animation 10s infinite linear;
// }


.zubuz-video{
  &-column{
    padding: 30px;
    border-radius: 20px;
    border: 1px solid var(--light-color);
    background-color: var(--white-bg);
  }
  &-wrap{
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    margin-right: 20px;
    @include max(991px) {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}

.zubuz-review-video {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

img.video-sevice {
  @include max(991px) {
    width: 75px;
  }
  
}