.zubuz-tools{
  &-wrap{
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    @include max(1199px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @include max(991px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include max(767px) {
      grid-template-columns: 1fr 1fr;
    }
    // @include max(375px) {
    //   grid-template-columns: 1fr;
    // }
  }
  &-item{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid var(--light-color);
  }
}