

.zubuz-countdown {
  &-wrap{
    max-width: 856px;
    margin: 0 auto;
    display: grid;
    gap: 24px;
    margin-bottom: 50px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @include max(991px) {
      grid-template-columns:  1fr 1fr;
    }
    @include max(767px) {
      margin-bottom: 30px;
    }
  }
  &-item{
    width: 100%;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    flex-direction: column;
    background-color: var(--dark-bg);
    @include max(767px) {
      height: 150px;
    }
    
    p{
      font-weight: 600;
      margin-top: 7px;
      color: var(--secondary-color);
      @include max(991px) {
        font-size: 20px;
      }
    }
  }
  
}
.number{
  color: var(--white-color);
  font-size: 85px;
  font-weight: 700;
  line-height: 1.05em; /* 116.667% */
  letter-spacing: -0.1px;
  font-family: $heading-font;
  @include max(1400px) { 
    font-size: 60px;
  }
  @include max(767px) { 
    font-size: 48px;
  }
  @include max(479px) { 
    font-size: 36px;
  }
}

.zubuz-coming-newsletter {
  max-width: 500px;
  margin: 0 auto;
  position: relative;
    input{
      color: #666;
      padding-right: 158px!important;
      margin-bottom: 12px;
      border: 1px solid #ccc!important;
      @include max(767px) {
        padding-right: 24px!important;
      }
      &:focus, 
      &:focus{
         border: 1px solid var(--primary-color)!important;
      }
        &::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
          color:    #666;
          opacity: 1;
        }
        &:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
          color:    #666;
          opacity:  1;
        }
        &::-moz-placeholder{ /* Mozilla Firefox 19+ */
          color:    #666;
          opacity:  1;
        }
        &:-ms-input-placeholder{ /* Internet Explorer 10-11 */
          color:    #666;
          opacity: 1;
        }
        &::-ms-input-placeholder{ /* Microsoft Edge */
          color:    #666;
          opacity: 1;
        }

        &::placeholder{ /* Most modern browsers support this now. */
          color:    #666;
          opacity: 1;
        }

   }

   p{
    color: #666;
    text-align: center;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.5px;
   }
  
}

button#zubuz-notified-btn {
  border-radius: 3px;
  width: 150px;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  position: absolute;
  top: 5px;
  right: 5px;
  transition: all 0.4s;
  color: var(--white-color);
  background-color: var(--blue-bg);
  &:hover{
    color: var(--heading-color);
    background-color: var(--secondary-color);
  }

  @include max(767px) {
    position: static;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
}