

.zubuz-accordion{
  &-wrap{
    margin-right: 65px;
    @include max(1199px) {
      margin-right: 20px;
    }
    @include max(991px) {
      margin-top: 40px;
    }
  }
  &-item{
    padding: 23px 24px;
    position: relative;
    margin-bottom: 20px;
    border-radius: 20px;
    transition: all 0.4s;
    border: 1px solid var(--light-color);
    background-color: var(--light-bg);
    &:last-child{
      margin-bottom: 0;
    }
    &.open .zubuz-active-icon{
      transform: rotate(180deg);
      svg{
        path{
          stroke: var(--secondary-color);
        }
      }
    }
    &.open{
      background-color: var(--dark-bg);
      .zubuz-accordion-body{
        display: block;
        color: var(--white-color);
      }
      .zubuz-accordion-header{
        h3{
          color: var(--secondary-color);
        }
      }
    }
  }
  &-header{
    cursor: pointer;
    position: relative;
    h3{
      @include max(767px) {
        font-size: 24px;
        padding-right: 25px;
      }
    }
   
  }
  &-body{
    display: none;
    padding-top: 15px;
    &.default{
      display: block;
    }
    @include max(991px) {
      padding: 24px 0 0;
    }
  }
}



.zubuz-accordion{
  &-wrap2{
    margin: 0;
    .zubuz-accordion-item{
      background-color: transparent;
      &.open .zubuz-active-icon{
        transform: rotate(0);
        opacity: 0;
      }
      &.open .zubuz-inactive-icon{
        opacity: 1;
      }
    }
    .zubuz-accordion-header{
      padding-right: 20px;
      p{
        font-weight: 600;
        margin: 0;
      }
    }
    .zubuz-accordion-body{
      padding-right: 70px;
      @include max(991px) {
        padding-right: 0;
      }
      p{
        font-size: 18px;
        color: var(--body-color)!important;
      }
    }
    .zubuz-accordion-item.open{
      background-color: transparent;
    }
    .zubuz-active-icon, 
    .zubuz-inactive-icon{
      top: 0;
    }
    .zubuz-inactive-icon{
      opacity: 0;
    }
  }
}

.zubuz-active-icon, 
.zubuz-inactive-icon {
  position: absolute;
  top: 5px;
  right: 0px;
  transition: all 0.4s;
  svg{
    path{
      transition: all 0.4s;
    }
  }
}