@media (min-width: 1400px){
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1296px;
  }

}

:root {
  @each $color, $shades in $colors {
    @each $shade, $value in $shades {
      #{'--'+$color}-#{$shade}: #{$value};
    }
  }
  @each $font, $sizes in $bodyfontScale {
    @each $size, $value in $sizes {
      #{'--fs-'+$font}-#{$size}: #{$value};
    }
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0px;
  padding: 0px;
}

html {
  box-sizing: border-box;
  height: 100%;
}

body {
  height: 100%!important;
  // line-height: var(--line-height);
  // font-size: var(--body-font-size);
  color: var(--body-color);
  font-family: $body-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  overflow-x: hidden;
  background-color: var(--light-bg1);
  counter-reset: my-sec-counter;
  font-size: 21px;
  line-height: 30px;
  &.styleguide {
    background-color: #dcdfe8;
  }
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #E5E7E8;
  padding: 0;
  margin: 0px;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.hidden {
  display: none !important;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

::-moz-selection {
  background-color: #989ffd;
  color: #fff;
}

::selection {
  background-color: #989ffd;
  color: #fff;
}

#{$all-buttons-active},
#{$all-buttons-focus},
#{$all-buttons-hover},
#{$all-text-inputs-focus},
#{$all-text-inputs-active},
#{$all-text-inputs-hover},
#{$all-text-inputs-invalid} {
  outline: none;
  box-shadow: none;
}
#{$all-buttons-active},
#{$all-buttons-focus},
#{$all-buttons-hover} {
  cursor: pointer;
}

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important; /* Black prints faster */
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

ul,ol,li { 
  margin: 0px;
  padding: 0px;
  list-style: none;
}

button { 
  padding: 0px;
  outline: none;
  border: none;
  display: inline-block;
  background-color: transparent;
}