
.zubuz-social-icon{
  ul{
    li{
      margin: 0 10px;
      display: inline-block;
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
      a{
        font-size: 20px;
        color: var(--body-color);
        transition: all 0.4s;
        &:hover{
          color: var(--primary-color);
        }
      }
    }
  }
}
.zubuz-social-icon{
  &.social-box{
    ul{
      li{
        margin: 0 5px;
        &:first-child{
          margin-left: 0;
        }
        &:last-child{
          margin-right: 0;
        }
        a{
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius:100px;
          border: 1px solid var(--heading-color);
          &:hover{
            color: var(--white-color);
            background-color: var(--primary-color);
            border: 1px solid var(--primary-color);
          }
        }
      }
    }
    &.social-box-white{
      ul{
        li{
          a{
            background-color: var(--white-bg);
            &:hover{
              background-color: var(--primary-color);
            }
          }
        }
      }
    }
    &.small-box{
      ul{
        li{
          a{
            width: 30px;
            height: 30px;
            font-size: 15px;
          }
        }
      }
    }
  }
  
}
