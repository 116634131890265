.zubuz-jobs-item{
  display: flex;
  align-items: center;
  padding: 30px;
  margin-bottom: 24px;
  border-radius: 20px;
  border: 1px solid #CCC;
  transition: all 0.4s;
  display: grid;
  grid-template-columns: 50% 30% 20%;
  @include max(991px) {
    row-gap: 24px;
    grid-template-columns: 100%;
  }
  &:hover{
    border: 1px solid var(--white-color);
    box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.08);
  }
  &-content{
    padding-right: 15%;
    @include max(1199px) {
      padding-right: 8%;
    }
    @include max(991px) {
      padding-right: 0;
    }
    &:last-child{
      padding-right: 0;
    }
    h3{
      margin-bottom: 15px;
    }
    span{
      display: block;
      font-weight: 600;
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
    }

  }
}

.zubuz-jobs-apply-btn {
  text-align: right;
  @include max(991px) {
    text-align: left;
  }
}

// career details
.zubuz-career-details-content{
  h2{
    margin-bottom: 20px;
  }
  h3{
    margin-bottom: 15px;
  }
  img{
    margin-bottom: 20px;
    border-radius: 10px;
  }
}

.zubuz-position-requirement-item{
  margin-top: 30px;
  margin-bottom: 25px;
  &:last-child{
    margin-bottom: 0;
  }
  span{
    font-weight: 600;
  }
  p{
    padding-left: 35px;
    position: relative;
    margin: 0;
    &::before{
      content: "";
      left: 20px;
      top: 8px;
      width: 6px;
      height: 6px;
      border-radius: 10px;
      position: absolute;
      background-color: var(--dark-bg);
    }
  }
}

.zubuz-job-role{
  padding: 24px;
  border-radius: 10px;
  background-color: var(--blue-bg);
  @include max(991px) {
    margin-top: 35px;
  }
  h3{
    margin-bottom: 20px;
    color: var(--white-color);
  }
  ul{
    li{
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
      color: var(--white-color);
      position: relative;
      padding-left: 30px;
      &:last-child{
        margin-bottom: 0;
      }
      img{
        position: absolute;
        left: 0;
        top: 4px;
      }
    }
  }
  a.zubuz-default-btn{
    width: 100%;
    text-align: center;
    margin-top: 24px;
    color: var(--primary-color);
    background-color: var(--white-bg);
  }

}