
header.zubuz-header-section {
  position: fixed;
  z-index: 9;
  width: 100%;
  top: 0;
  padding: 15px 0;
  transition: all 0.4s;
}
header.zubuz-header-section.sticky-menu{
  box-shadow: 0 4px 80px rgba(0,0,0,.1);
  background-color: var(--white-bg);
}
header.zubuz-header-section.white-menu.sticky-menu{
  background-color: var(--dark-bg);
}


.zubuz-header-btn-wrap{
  display: flex;
  align-items: center;
}
.zubuz-login-btn{
  font-size: 16px;
  font-weight: 700;
  color: var(--heading-color);
  transition: all 0.4s;
  margin-right: 40px;
  @include max(767px) {
    margin-right: 15px;
  }
  &:hover{
    color: var(--primary-color);
  }
}
.site-header .mobile-menu-trigger{
  @include max(767px){
    margin-left: 25px;
  }
}
