input[type="text"],
input[type="email"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="time"],
input[type="date"],
input[type="search"],
input, textarea {
  margin: 0;
  height: 60px;
  width: 100%;
  color:var(--body-color);
  font-size: 16px;
  border-radius: 10px;
  background-color: var(--white-bg);
  padding: 15px 24px !important;
  border: none!important;
  transition: all 0.4s;
  letter-spacing: 0.5px;
  border: 1px solid var(--light-color)!important;
}
input:focus, 
textarea:focus{
  border: 1px solid var(--primary-color)!important;
}
textarea {
  height: 150px;
  resize: none;
  border-radius: 20px;
  @include max(767px) {
    height: 100px;
  }
}

input::-webkit-input-placeholder, 
textarea::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
    color:    var(--body-color);
    opacity: 1;
}
input:-moz-placeholder, 
textarea:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
   color:    var(--body-color);
   opacity:  1;
}
input::-moz-placeholder, 
textarea::-moz-placeholder{ /* Mozilla Firefox 19+ */
   color:    var(--body-color);
   opacity:  1;
}
input:-ms-input-placeholder, 
textarea:-ms-input-placeholder{ /* Internet Explorer 10-11 */
   color:    var(--body-color);
   opacity: 1;
}
input::-ms-input-placeholder, 
textarea::-ms-input-placeholder{ /* Microsoft Edge */
   color:    var(--body-color);
   opacity: 1;
}

input::placeholder, 
textarea::placeholder{ /* Most modern browsers support this now. */
   color:    var(--body-color);
   opacity: 1;
}

.zubuz-subscribe{
   &-one{
      position: relative;
      input{
      height: 50px;
      border-radius: 3px;
      color: var(--body-color);
      border: 1px solid #ccc!important;
      &:focus, 
      &:focus{
         border: 1px solid var(--primary-color)!important;
      }
      &::-webkit-input-placeholder, 
      &::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
         color:    #666666;
         opacity: 1;
      }
      &:-moz-placeholder, 
      &:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
         color:    #666666;
         opacity:  1;
      }
      &::-moz-placeholder, 
      &::-moz-placeholder{ /* Mozilla Firefox 19+ */
         color:    #666666;
         opacity:  1;
      }
      &:-ms-input-placeholder, 
      &:-ms-input-placeholder{ /* Internet Explorer 10-11 */
         color:    #666666;
         opacity: 1;
      }
      &::-ms-input-placeholder, 
      &::-ms-input-placeholder{ /* Microsoft Edge */
         color:    #666666;
         opacity: 1;
      }

      &::placeholder, 
      &::placeholder{ /* Most modern browsers support this now. */
         color:    #666666;
         opacity: 1;
      }
   }
   }

   &-two{
      max-width: 450px;
      margin: 0 auto;
      position: relative;
      input{
      height: 60px;
      border-radius: 50px;
      color: var(--body-color);
      border: none;
      background-color: var(--white-bg);
      &:focus, 
      &:focus{
         border: 1px solid var(--primary-color)!important;
      }
      &::-webkit-input-placeholder, 
      &::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
         color:    #666666;
         opacity: 1;
      }
      &:-moz-placeholder, 
      &:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
         color:    #666666;
         opacity:  1;
      }
      &::-moz-placeholder, 
      &::-moz-placeholder{ /* Mozilla Firefox 19+ */
         color:    #666666;
         opacity:  1;
      }
      &:-ms-input-placeholder, 
      &:-ms-input-placeholder{ /* Internet Explorer 10-11 */
         color:    #666666;
         opacity: 1;
      }
      &::-ms-input-placeholder, 
      &::-ms-input-placeholder{ /* Microsoft Edge */
         color:    #666666;
         opacity: 1;
      }

      &::placeholder, 
      &::placeholder{ /* Most modern browsers support this now. */
         color:    #666666;
         opacity: 1;
      }
   }
   }

   &-three{
      max-width: 500px;
      margin: 0 auto;
      position: relative;
      input{
      height: 60px;
      border-radius: 50px;
      color: var(--body-color);
      padding-left: 55px!important;
      border: 1px solid #ccc!important;
      &:focus, 
      &:focus{
         border: 1px solid var(--primary-color)!important;
      }
      &::-webkit-input-placeholder, 
      &::-webkit-input-placeholder{ /* WebKit, Blink, Edge */
         color:    #666666;
         opacity: 1;
      }
      &:-moz-placeholder, 
      &:-moz-placeholder{ /* Mozilla Firefox 4 to 18 */
         color:    #666666;
         opacity:  1;
      }
      &::-moz-placeholder, 
      &::-moz-placeholder{ /* Mozilla Firefox 19+ */
         color:    #666666;
         opacity:  1;
      }
      &:-ms-input-placeholder, 
      &:-ms-input-placeholder{ /* Internet Explorer 10-11 */
         color:    #666666;
         opacity: 1;
      }
      &::-ms-input-placeholder, 
      &::-ms-input-placeholder{ /* Microsoft Edge */
         color:    #666666;
         opacity: 1;
      }

      &::placeholder, 
      &::placeholder{ /* Most modern browsers support this now. */
         color:    #666666;
         opacity: 1;
      }
   }
   }
}
.zubuz-input-email {
   position: absolute;
   top: 14px;
   left: 24px;
}
.zubuz-subscription-btn{
   top: 5px;
   right: 5px;
   position: absolute;
   &.one{
      font-size: 14px;
      padding: 8px 15px;
   }
   &.two, &.three{
      border-radius: 50px;
      font-size: 16px;
      padding: 13px 31px;
      @include max(479px) {
         position: static;
         margin-top: 20px;
         width: 100%;
         color: var(--primary-color);
         background-color: var(--white-bg);
         &:hover{
            color: var(--heading-color);
         }
      }
   }
   @include max(479px) {
      &.three{
         color: var(--white-color);
         background-color: var(--primary-color);
      }
   }
   
   
   
}

// home one footer subscribe 1