@import url('./vendor/bootstrap.min.css');
/* @import url('./vendor/fontawesome.css'); */
/* @import url('./vendor/icomoon.css'); */
/* @import url('./vendor/rt-plugins.css'); */
@import url('./vendor/swiper-bundle.min.css');
@import url('./magnific-popup.css');
@import url('./slick.css');
@import url('./animate.css');

/* For WebKit browsers (Chrome, Safari, etc.) */
.zubuz-table-wrap::-webkit-scrollbar {
    width: 12px; /* Change scrollbar width */
}

.zubuz-table-wrap::-webkit-scrollbar-track {
    background: #f1f1f1; /* Scrollbar track color */
}

.zubuz-table-wrap::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar thumb color */
    border-radius: 10px; /* Round the corners of the scrollbar */
    border: 2px solid #f1f1f1; /* Add a border around the scrollbar thumb */
}

/* Hover effect */
.zubuz-table-wrap::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Scrollbar thumb color on hover */
}

/* For Firefox */
.zubuz-table-wrap {
    scrollbar-width: thin; /* Change scrollbar width */
    scrollbar-color: #888 #f1f1f1; /* Thumb color, Track color */
}

/* For Internet Explorer and Edge */
.zubuz-table-wrap {
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
