.zubuz-iconbox{
  &-wrap-left{
    display: flex;
    max-width: 590px;
    margin-bottom: 24px;
    text-align: left;
    &:last-child{
      margin-bottom: 0;
    }
    .zubuz-iconbox-icon{
      margin-right: 24px;
    }
    &.iconbox-left-border{
      padding: 28px;
      border-radius: 20px;
      border: 1px solid #CCC;
      max-width: 100%;
      transition: all 0.4s;
      margin-bottom: 24px!important;
      &:hover{
        border: 1px solid var(--white-color);
        box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.08);
      }
    }
    &:hover .zubuz-iconbox-number{
        color: var(--heading-color);
        background-color: var(--secondary-color);
        border: 1px solid var(--secondary-color);
    }
  }
  &-header{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    &-data{
      h4{
        margin-bottom: 3px;
      }
      p{
        font-size: 16px;
      }
    }
    .zubuz-iconbox-icon{
      width: 80px;
      height: 80px;
      border: 1px solid var(--light-color);
      background-color: transparent;
      margin-bottom: 0;
    }
   
  }
  &-icon{
    width: 60px;
    height: 60px;
    display: flex;
    flex-shrink: 0;
    margin-bottom: 30px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-color);
    &.none-bg{
      width: auto;
      height: auto;
      display: block;
      margin-bottom: 0;
      background-color: transparent;
    }
  }
  &-data{
    span{
      font-weight: 600;
      display: block;
      margin-bottom: 10px;
    }
    h3{
      margin-bottom: 15px;
    }

    &.data-small{
      p{
        font-size: 18px;
      }
    }
    &.light{
      h3, span, p{
        color: var(--white-color);
      }
    }
  }
  &-number{
    width: 60px;
    height: 60px;
    display: flex;
    flex-shrink: 0;
    font-size: 24px;
    font-weight: 600;
    border-radius: 100px;
    align-items: center;
    margin-right: 24px;
    transition: all 0.4s;
    justify-content: center;
    color: var(--white-color);
    border: 1px solid var(--white-color);
    
  }
  &-thumb{
    margin-top: 24px;
    img{
      width: 100%;
      box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.08);
    }
  }
  &-btn{
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: var(--heading-color)!important;
    position: relative;
    transition: all 0.4s;
    span{
      margin: 0;
      line-height: 1;
      opacity: 0;
      z-index: 1;
      visibility: hidden;
      transition: all 0.4s;
      background-color: var(--white-bg);
    }
    img{
      transition: all 0.5s;
      position: absolute;
    }
    &:hover img{
      transform: translateX(90px);
    }
    &:hover span{
      opacity: 1;
      visibility: visible;
    }

  }
  // end iconbox left one
  &-wrap{
    &.center{
      text-align: center;
      .zubuz-iconbox-icon{
        margin: 0 auto 30px;
      }
    }
    padding: 30px;
    margin-bottom: 24px;
    border-radius: 10px;
    transition: all 0.4s;
    background-color: var(--white-bg);
    border: 1px solid var(--light-color);
    &:hover{
      border: 1px solid var(--heading-color);
    }
  }

  &-wrap2{
    border-radius: 10px;
    padding: 40px 40px 0;
    margin-bottom: 24px;
    background-color: var(--light-bg);
  }
  
}