
.zubuz-portfolio-menu ul li.active{
  color: #fff ;
  position: relative;
  transition: all 0.4s;
  background-color: #092C4C;
}

.zubuz-portfolio {
  &-menu{
    text-align: center;
    margin-bottom: 50px;
    @include max(767px) {
      margin-bottom: 40px;
    }
    ul{
      li{
        font-size: 16px;
        font-weight: 700;
        padding: 20px 24px;
        margin: 0 3px;
        line-height: 1;
        border-radius: 3px;
        text-align: center;
        cursor: pointer;
        transition: all 0.4s;
        display: inline-block;
        border: 1px solid var(--heading-color);
        @include max(479px) {
          font-size: 15px;
          padding: 14px 12px;
        }
        &::first-child{
          margin-left: 0;
        }
        &:last-child{
          margin-right: 0;
        }
        &.active{
          color: #fff ;
          background-color: var(--blue-bg);
          border: 1px solid var(--primary-color);
        }
      }
      
    }
  }
  &-column{
    margin-left: -24px;
  }
  &-wrap{
    &:hover .zubuz-portfolio-data{
      opacity: 1;
      bottom: 40px;
      visibility: visible;
    }
    &:hover .zubuz-portfolio-thumb:before{
      opacity: 1;
      visibility: visible;
    }
  }
  &-thumb {
    margin-left: 24px;
    margin-bottom: 24px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    &:before{
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      border-radius: 10px;
      position: absolute;
      transition: all 0.4s;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 50.52%, #000 89.06%);
      mix-blend-mode: multiply;
    }
  }
  &-data{
    bottom: 0;
    left: 40px;
    opacity: 0;
    visibility: hidden;
    width: calc(100% - 80px);
    position: absolute;
    transition: all 0.4s;
    @include max(1199px){
      left: 30px;
      width: calc(100% - 60px);
    }
    h3{
      font-size: 40px;
      margin-bottom: 15px;
      color: var(--white-color);
      @include max(1199px) {
        font-size: 30px;
      }
    }
    p{
      margin: 0;
      color: var(--white-color);
    }
  }
  &-icon{
    top: 30px;
    right: 0;
    position: absolute;
  }
  
}


.zubuz-portfolio {
  &-wrap2{
    &:hover .zubuz-portfolio-thumb2:before{
      opacity: 1;
      visibility: visible;
    }
  }
  &-thumb2 {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      aspect-ratio: 8.5/4;
      object-fit: cover;
      object-position: top center;
    }
    &:before{
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      border-radius: 10px;
      position: absolute;
      transition: all 0.4s;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 50.52%, #000 89.06%);
      mix-blend-mode: multiply;
    }
  }
  &-data2{
    position: relative;
    padding: 24px 0 40px;
    @include max(991px) {
      padding: 24px 0;
    }
    h3{
      font-size: 40px;
      margin-bottom: 15px;
      @include max(1199px) {
        font-size: 30px;
      }
    }
    p{
      margin: 0;
    }
  }
  &-icon2{
    right: 0;
    top: 60px;
    position: absolute;
    svg {
      @include max(991px) {
        width: 30px;
      }
      path{
        transition: all 0.4s;
        stroke: var(--heading-color);
        &:hover{
          stroke: var(--primary-color);
        }
      }
    }
  }
  
}

// porfolio details
.zubuz-portfolio-details-thumb{
  margin-bottom: 40px;
  img{
    border-radius: 10px;
    aspect-ratio: 8/5;
  }
}

.zubuz-portfolio{
  &-info{
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    margin-bottom: 70px;
    justify-content: space-between;
    @include max(991px) {
      justify-content: center;
    }
    &-item{
      p{
        font-weight: 600;
        margin-bottom: 15px;
        color: #7F8995;
        @include max(991px) {
          margin-bottom: 10px;
        }
      }
      @include max(991px) {
        flex: 0 0 50%;
        text-align: center;
      }
    }
    
  }

}

.zubuz-portfolio-details{
  
  &-content{
    &-wrap{
      margin-right: 80px;
      @include max(991px) {
        margin-right: 0;
        margin-bottom: 35px;
      }
    }
    margin-bottom: 80px;
    &:last-child{
      margin-bottom: 0;
    }
    @include max(991px) {
      margin-bottom: 40px;
      text-align: center;
    }
    h2{
      margin-bottom: 24px;
    }
    h4{
      margin-bottom: 20px;
    }
    ul{
      @include max(991px) {
        text-align: left;
      }
      li{
        position: relative;
        padding-left: 20px;
        &:before{
          content: "";
          left: 0;
          top: 11px;
          width: 6px;
          height: 6px;
          border-radius: 10px;
          position: absolute;
          background-color: var(--body-color);
        }
      }
    }
  }
  
}

.zubuz-portfolio-details-thumb {
  &-wrap{
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &-item{
    border-radius: 10px;
    overflow: hidden;
  }
  
}