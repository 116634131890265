.zubuz-icon-list{
  margin-top: 24px;
  ul{
    li{
      font-weight: 600;
      font-size: 16px;
      position: relative;
      padding-left: 30px;
      text-align: left;
      display: inline-block;
      margin-right: 20px;
      img{
        position: absolute;
        left: 0;
        top: 4px;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
}