.zubuz-brand-logo-content{
  margin-right: 30px;
  @include max(991px) {
    margin: 0 0 30px;
    text-align: center;
  }
  h3{
    font-weight: 600;
    color:var(--white-color)
  }
}
.zubuz-brand-item {
  padding: 0 20px;
}
.zubuz-brand-slider2{
  direction: rtl;
  margin-top: 30px;
}

.zubuz-brand-logo-wrap{
  p{
    margin-bottom: 50px;
    text-align: center;
  }
  padding-bottom: 130px;
  border-bottom: 1px solid var(--light-color);
  @include max(991px) {
    padding-bottom: 100px;
  }
  @include max(767px) {
    padding-bottom: 80px;
  }
}